/* eslint-disable no-template-curly-in-string */

// Cloudinary Templates
export const FORMAT_INSTRUCTIONS = '{formatInstructions}';
export const OPTIONS = '${options}';
export const TYPE = '{type}';
export const WIDTH = '{width}';

//  Cloudinary Values
export const FEATURED_FORGE_TILE_TRANSFORM =
  'ar_16:9,g_auto,q_auto:good,w_825,c_fill,f_jpg/e_gradient_fade:15,y_-0.7,b_black';
export const DEFAULT_FORGE_TILE_TRANSFORM = 'ar_16:9,g_auto,q_auto:good,w_400,c_fill,f_jpg/';
export const FULLSCREEN_GRADIENT_TRANSFORM =
  'e_gradient_fade,y_-0.5,b_black/e_gradient_fade,x_0.8,b_black';
export const WELCOME_FULLSCREEN_GRADIENT_TRANSFORM =
  'w_1920,h_1080/e_gradient_fade,y_-0.5,b_black/e_gradient_fade,x_0.8,b_black';
export const BACKGROUND_IMAGE_BLUR_TRANSFORM = 'e_blur:1000';
export const LOGO_WIDTH = '200,h_200';
export const PNG_TYPE = 'png';
export const WORDMARK_WIDTH = '300,h_300,c_pad';
