import { useEffect } from 'react';
import { setIsBackgrounded } from 'store/app';
import { useAppDispatch } from 'store/hooks';

import { useReloadApp } from '../useReloadApp/useReloadApp';

export function useIsAppBackgrounded() {
  const dispatch = useAppDispatch();
  const reloadApp = useReloadApp();

  useEffect(() => {
    // Only initialize Sony SDK Listeners if on a PS
    const backgroundListener = () => dispatch(setIsBackgrounded(true));
    const foregroundListener = () => dispatch(setIsBackgrounded(false));

    window.msdk?.addEventListener('onAppResume', reloadApp);
    window.msdk?.addEventListener('onBackground', backgroundListener);
    window.msdk?.addEventListener('onForeground', foregroundListener);

    return () => {
      window.msdk?.removeEventListener('onAppResume', reloadApp);
      window.msdk?.removeEventListener('onBackground', backgroundListener);
      window.msdk?.removeEventListener('onForeground', foregroundListener);
    };
  }, [dispatch, reloadApp]);
}
