import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import adobeReducer from 'store/adobe/adobeSlice';
import { apiSlice } from 'store/apiSlice';
import appReducer from 'store/app/appSlice';
import bookmarksReducer from 'store/bookmarks/bookmarksSlice';
import brandingReducer from 'store/branding/brandingSlice';
import configReducer from 'store/config/configSlice';
import experienceReducer from 'store/experience/experienceSlice';
import { logger } from 'store/middleware';
import mlbAuthReducer from 'store/mlbAuth/mlbAuthSlice';
import networkConnectionReducer from 'store/networkConnection/networkConnectionSlice';
import paywallReducer from 'store/paywall/paywallSlice';
import playByPlayReducer from 'store/playByPlay/playByPlaySlice';
import playerReducer from 'store/player/playerSlice';
import profileReducer from 'store/profile/profileSlice';
import selectedVideoReducer from 'store/selectedVideo/selectedVideoSlice';

export const apiMiddleware = [apiSlice.middleware];
// filters out "null" middleware
const middleware: any[] = [logger, ...apiMiddleware].filter((val) => val);

export const reducers = {
  adobe: adobeReducer,
  app: appReducer,
  bookmarks: bookmarksReducer,
  branding: brandingReducer,
  config: configReducer,
  experience: experienceReducer,
  mlbAuth: mlbAuthReducer,
  networkConnection: networkConnectionReducer,
  paywall: paywallReducer,
  playByPlay: playByPlayReducer,
  player: playerReducer,
  profile: profileReducer,
  selectedVideo: selectedVideoReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
};

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  reducer: reducers,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type GetState = typeof store.getState;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

setupListeners(store.dispatch);
