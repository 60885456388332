import { getElementById, isNeitherNilOrEmpty } from './utils';
import { utteranceFromElementOrText } from './utteranceFromElementOrText';

/**
 * Returns a string created from text collected from one or more elements relating
 * to ids provided
 *
 * **Example Usage**:
 * ```
 * utteranceFromIdReferenceList('a b c');
 * ```
 *
 *
 * **Simple**:
 * ```
 * <div id="a">A</div>
 * <div id="b">B</div>
 * <div id="c">C</div>
 * ```
 * Output: ```"A B C"```
 *
 *
 * **Advanced**:
 * ```
 * <div id="a" aria-label="Not A">A</div>
 * <div id="b"><span>B</span></div>
 * <div id="c" aria-labelledby="c-label">C</div>
 * <div id="c-label">Not C</div>
 * ```
 * Output: ```"Not A B Not C"```
 *
 * @param idReferenceList - A space delimited string of dom element ids
 * @see utteranceFromElementOrText
 */

export const utteranceFromIdReferenceList = (idReferenceList: string) => {
  return idReferenceList
    .split(' ')
    .map(getElementById)
    .filter((element) => !!element)
    .map((element) => utteranceFromElementOrText(element))
    .filter(isNeitherNilOrEmpty)
    .join(' ');
};
