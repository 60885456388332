import {
  Content,
  Experience,
  MediaInfo,
  MediaState,
  MediaType,
  PlaybackSession,
} from '@mlbtv-clients/services';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DAIParams, IMAIdentifier } from 'ads';
import { MediaParams } from 'analytics/adobe';
import { LIVE_MLB_FEED } from 'constants/feeds';
import { LIVE_AIRING } from 'constants/gameState';
import { Airing, BamSdkConvivaData, MilestoneType } from 'experience/DSS';
import { MlbTrackingData } from 'experience/MLB';
import { ContentField } from 'experience/MLB/constants';
import { getContentFieldValue } from 'experience/MLB/utils';
import { ForgeVideoData } from 'services/forge';
import { RootState } from 'store';
import { EpgAndStatsGame, EpgAudioFeed, EpgVideoFeed } from 'store/epg';
import { selectExperience } from 'store/experience';
import { resetPlayer, selectCurrentTime, selectSeekOffset } from 'store/player';
import { getTimeInMs } from 'utils/date';
import { isForgeContentLive } from 'utils/forge';
import { getAbsoluteDateWithOffset } from 'utils/gameVideo';
import { v4 as uuidv4 } from 'uuid';

import { InningMilestone } from './types';
import {
  formatDSSMilestones,
  formatMlbMilestones,
  getDSSAbsoluteStreamStartTime,
  getDSSBroadcastStartTime,
  getMlbAbsoluteStreamStartTime,
  getMlbBroadcastStartTime,
  orderAudioTracks,
  orderVideoFeeds,
} from './utils';

export interface SelectedVideoState {
  forgeVideo: {
    adobe: MediaParams | null;
    data: ForgeVideoData | null;
    fguid: string;
  };
  game: {
    adobe: MediaParams | null;
    airing: Airing | null;
    conviva: BamSdkConvivaData | null;
    feed: EpgAudioFeed | EpgVideoFeed | null;
    mlbAdParams: DAIParams | null;
    mlbContentData: Content[] | null;
    mlbMediaInfo: MediaInfo | null;
    mlbTrackingData: MlbTrackingData | null;
    playbackUrl: string;
    selectedGame: EpgAndStatsGame | null;
  };
}

export const initialState: SelectedVideoState = {
  forgeVideo: {
    adobe: null,
    data: null,
    fguid: '',
  },
  game: {
    adobe: null,
    airing: null,
    conviva: null,
    feed: null,
    mlbAdParams: null,
    mlbContentData: null,
    mlbMediaInfo: null,
    mlbTrackingData: null,
    playbackUrl: '',
    selectedGame: null,
  },
};

export const selectedVideoSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(resetPlayer, (state) => ({
      ...initialState,
      game: {
        ...initialState.game,
        selectedGame: state.game.selectedGame,
      },
    }));
  },
  initialState,
  name: 'selectedVideo',
  reducers: {
    setConvivaData(state: SelectedVideoState, action: PayloadAction<BamSdkConvivaData>) {
      state.game.conviva = action.payload;
    },
    setForgeVideoAdobeMediaParams(state: SelectedVideoState, action: PayloadAction<MediaParams>) {
      state.forgeVideo.adobe = action.payload;
    },
    setGameAdobeMediaParams(state: SelectedVideoState, action: PayloadAction<MediaParams>) {
      state.game.adobe = action.payload;
    },
    setGamePlaybackUrl(state: SelectedVideoState, action: PayloadAction<string>) {
      state.game.playbackUrl = action.payload;
    },
    setMlbAdParams(
      state: SelectedVideoState,
      action: PayloadAction<
        PlaybackSession['adExperience'] & {
          adScenarios: PlaybackSession['adScenarios'];
          token: string;
        }
      >,
    ) {
      const { adEngineIdentifiers, adScenarios, adsEnabled, token } = action.payload;
      const { mlbTrackingData, playbackUrl } = state.game;

      const isLive = mlbTrackingData?.isLive === LIVE_MLB_FEED;

      const type: DAIParams['type'] = isLive ? 'live' : 'vod';

      const identifiers = adEngineIdentifiers.reduce(
        (acc, identifier) => {
          acc[identifier.name as keyof IMAIdentifier] = identifier.value;
          return acc;
        },
        {} as {
          [key in keyof IMAIdentifier]: string;
        },
      );

      // Because we only pass one ad scenario to initPlaybackSession (AdExperienceType.GOOGLE_STANDALONE_AD_PODS), we can safely assume we can use the first ad scenario
      const value: DAIParams = {
        adParamsObj: adScenarios[0]?.adParamsObj,
        enabled: adsEnabled,
        originalUrl: playbackUrl,
        token,
        type,
        ...identifiers,
      };

      state.game.mlbAdParams = value;
    },
    setMlbContentData(state: SelectedVideoState, action: PayloadAction<Content[]>) {
      state.game.mlbContentData = action.payload;
    },
    setMlbMediaInfo(state: SelectedVideoState, action: PayloadAction<MediaInfo>) {
      state.game.mlbMediaInfo = action.payload;
    },
    setMlbTrackingData(state: SelectedVideoState, action: PayloadAction<MlbTrackingData>) {
      state.game.mlbTrackingData = action.payload;
    },
    setSelectedAiring(state: SelectedVideoState, action: PayloadAction<Airing>) {
      state.game.airing = action.payload;
    },
    setSelectedFeed(
      state: SelectedVideoState,
      action: PayloadAction<EpgAudioFeed | EpgVideoFeed | null>,
    ) {
      state.game.feed = action.payload;
    },
    setSelectedForgeVideo(state: SelectedVideoState, action: PayloadAction<ForgeVideoData>) {
      state.forgeVideo.fguid = uuidv4();
      state.forgeVideo.data = action.payload;
    },
  },
});

// Actions
export const {
  setConvivaData,
  setForgeVideoAdobeMediaParams,
  setGameAdobeMediaParams,
  setGamePlaybackUrl,
  setMlbAdParams,
  setMlbContentData,
  setMlbMediaInfo,
  setMlbTrackingData,
  setSelectedAiring,
  setSelectedFeed,
  setSelectedForgeVideo,
} = selectedVideoSlice.actions;

// Selectors
export const selectAdobeMediaParams = (state: RootState) =>
  state.selectedVideo.forgeVideo.data
    ? state.selectedVideo.forgeVideo.adobe
    : state.selectedVideo.game.adobe;

export const selectAiring = (state: RootState) => state.selectedVideo.game.airing;

export const selectContentId = (state: RootState) => state.selectedVideo.game.feed?.contentId ?? '';

export const selectConvivaData = (state: RootState) => state.selectedVideo.game.conviva;

export const selectFeed = (state: RootState) => state.selectedVideo.game.feed;

export const selectFguid = (state: RootState) => {
  if (state.selectedVideo.forgeVideo.data) return state.selectedVideo.forgeVideo.fguid;

  return state.experience.experience === Experience.LEGACY
    ? state.selectedVideo.game.conviva?.fguid ?? ''
    : state.selectedVideo.game.mlbTrackingData?.fguid ?? '';
};

export const selectAbsoluteStreamStartTime = (state: RootState) => {
  const experience = selectExperience(state);
  const airing = selectAiring(state);
  const mlbMediaInfo = selectMlbMediaInfo(state);

  return experience === Experience.LEGACY
    ? getDSSAbsoluteStreamStartTime(airing?.milestones)
    : getMlbAbsoluteStreamStartTime(mlbMediaInfo?.milestones);
};

export const selectLatestInningMilestone = (state: RootState): InningMilestone | undefined => {
  const inningMilestones = selectInningMilestones(state);

  return inningMilestones[inningMilestones.length - 1];
};

export const selectGameLiveMilestone = (state: RootState) => {
  const mediaInfo = selectMlbMediaInfo(state);

  const gameLiveMilestone = mediaInfo?.milestones.find(
    ({ milestoneType }) => milestoneType === MilestoneType.GAME_LIVE,
  );

  return gameLiveMilestone;
};

export const selectIsStreamPastGameLiveMilestone = (state: RootState) => {
  const currentTime = selectCurrentTime(state);
  const gameLiveMilestone = selectGameLiveMilestone(state);

  const gameLiveTime = gameLiveMilestone?.relativeTime;

  return !!gameLiveTime && currentTime > gameLiveTime;
};

export const selectBroadcastStartTime = (state: RootState) => {
  const experience = selectExperience(state);
  const airing = selectAiring(state);
  const mlbMediaInfo = selectMlbMediaInfo(state);

  return experience === Experience.LEGACY
    ? getDSSBroadcastStartTime(airing?.milestones)
    : getMlbBroadcastStartTime(mlbMediaInfo?.milestones);
};

export const selectGamePlaybackUrl = (state: RootState) =>
  state.selectedVideo.game.playbackUrl ?? '';

export const selectIsGameContent = (state: RootState) => !!selectGamePlaybackUrl(state);

export const selectMlbAdParams = (state: RootState) => state.selectedVideo.game.mlbAdParams;

export const selectMlbContentData = (state: RootState) => state.selectedVideo.game.mlbContentData;

export const selectVideoFeeds = createSelector(selectMlbContentData, (contentData): Content[] => {
  const mlbVideoFeeds = orderVideoFeeds([
    ...(contentData ?? []).filter(
      ({ mediaState }) =>
        mediaState.state !== MediaState.OFF && mediaState.mediaType === MediaType.VIDEO,
    ),
  ]);

  const sortedVideoFeeds: Content[] = mlbVideoFeeds.map((mlbFeed) => ({
    ...mlbFeed,
    audioTracks: orderAudioTracks([...mlbFeed.audioTracks]),
  }));

  return sortedVideoFeeds;
});

export const selectSelectedVideoFeed = createSelector(
  [selectVideoFeeds, selectContentId],
  (videoFeeds, contentId): Content | undefined =>
    videoFeeds.find((feed) => feed.contentId === contentId),
);

export const selectMlbMediaInfo = (state: RootState) => state.selectedVideo.game.mlbMediaInfo;

export const selectMlbTrackingData = (state: RootState) => state.selectedVideo.game.mlbTrackingData;

export const selectInningMilestones = createSelector(
  [selectExperience, selectAiring, selectMlbMediaInfo],
  (experience, airing, mlbMediaInfo) =>
    experience === Experience.LEGACY
      ? formatDSSMilestones(airing?.milestones)
      : formatMlbMilestones(mlbMediaInfo?.milestones),
);

export const selectInningFromProps = (
  _: RootState,
  { inning, isTop }: { inning: number; isTop: boolean },
) => ({ inning, isTop });

export const selectInningStartTime = createSelector(
  [selectInningMilestones, selectInningFromProps],
  (milestones, { inning, isTop }) => {
    const inningMilestone = milestones.find(
      (milestone) => milestone.inning === `${inning}` && milestone.isTop === isTop,
    );

    return inningMilestone?.milestoneTime;
  },
);

export const selectIsGameLive = (state: RootState) => {
  const experience = selectExperience(state);
  const airing = selectAiring(state);
  const mlbTrackingData = selectMlbTrackingData(state);

  return experience === Experience.LEGACY
    ? airing?.mediaConfig?.productType === LIVE_AIRING
    : mlbTrackingData?.isLive === LIVE_MLB_FEED;
};

export const selectEpgFeedByMediaId = (state: RootState, newMediaId: string) => {
  const { selectedGame } = state.selectedVideo.game;

  if (!selectedGame) return null;

  const { videoFeeds } = selectedGame;

  return videoFeeds.find(({ mediaId }) => mediaId === newMediaId);
};

export const selectGameTeamIds = (state: RootState) => {
  const experience = selectExperience(state);
  return experience === Experience.LEGACY
    ? selectDSSGameTeamIds(state)
    : selectMlbGameTeamIds(state);
};

export const selectDSSGameTeamIds = createSelector([selectAiring], (airing) => ({
  awayTeamId: parseInt(airing?.tags.find(({ type }) => type === 'away_team_id')?.value ?? '0', 10),
  homeTeamId: parseInt(airing?.tags.find(({ type }) => type === 'home_team_id')?.value ?? '0', 10),
}));

export const selectMlbGameTeamIds = createSelector([selectMlbContentData], (mlbContentData) => {
  const content = mlbContentData?.[0];

  if (!content) {
    return { awayTeamId: 0, homeTeamId: 0 };
  }

  return {
    awayTeamId: parseInt(getContentFieldValue(content, ContentField.AwayTeamId) ?? '0', 10),
    homeTeamId: parseInt(getContentFieldValue(content, ContentField.HomeTeamId) ?? '0', 10),
  };
});

export const selectCurrentInning = createSelector(
  [selectInningMilestones, selectAbsoluteStreamStartTime, selectCurrentTime, selectSeekOffset],
  (milestones, streamStartTime, currentTime, seekOffset) => {
    const absoluteDateWithOffset = getAbsoluteDateWithOffset({
      currentTime,
      seekOffset,
      streamStartTime,
    });

    const currentMilestone = milestones.findLast(
      ({ absoluteTime }) => getTimeInMs(absoluteDateWithOffset) > getTimeInMs(absoluteTime),
    );

    return {
      currentInning: currentMilestone?.inning ? parseInt(currentMilestone?.inning, 10) : undefined,
      isTop: currentMilestone?.isTop === undefined ? true : currentMilestone?.isTop,
    };
  },
);

// Forge Video
export const selectSelectedForgeVideo = (state: RootState) => state.selectedVideo.forgeVideo.data;

export const selectForgeVideoPlaybackUrl = (state: RootState) =>
  state.selectedVideo.forgeVideo.data?.url ?? '';

export const selectIsForgeVideoLive = (state: RootState) =>
  !!(
    state.selectedVideo.forgeVideo.data && isForgeContentLive(state.selectedVideo.forgeVideo.data)
  );

export const selectIsLive = createSelector(
  selectIsGameLive,
  selectIsForgeVideoLive,
  (isGameLive, isForgeVideoLive) => isGameLive || isForgeVideoLive,
);

export default selectedVideoSlice.reducer;
