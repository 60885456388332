import { Experience } from '@mlbtv-clients/services';
import { ROUTES } from 'constants/screens';
import { MLBExperienceStorage } from 'experience/MLB';
import { Bookmark } from 'store/bookmarks';
import { ConfigEnv } from 'store/config';

import {
  BOOKMARKS_KEY,
  CLOSED_CAPTIONS_KEY,
  CONFIG_ENV_KEY,
  DEV_SETTINGS_KEY,
  EPG_CACHE_BUST_KEY,
  EXPERIENCE,
  FAVORITE_TEAM_KEY,
  FOLLOWING_TEAMS_KEY,
  GETTING_STARTED_VIEWED_KEY,
  HIDE_SPOILERS_KEY,
  IS_MUTED_KEY,
  LOG_IN_AFTER_CREATE_KEY,
  LOGGING_ENABLED_KEY,
  MLB_EXPERIENCE_KEY,
  OKTA_AUTH_STORAGE_KEY,
  ONBOARDING_REDIRECT_PAGE,
  SHOW_TITLE_SAFE_OVERLAY_KEY,
  TEXT_TO_SPEECH_ENABLED_KEY,
} from './constants';
import { getItem, getSubItem, removeItem, setItem, setSubItem, StorageType } from './storage';

// App Env
export const getConfigEnvStorage = () =>
  getSubItem<ConfigEnv>(DEV_SETTINGS_KEY, CONFIG_ENV_KEY, {
    defaultValue: __APP_ENV__,
  });

export const setConfigEnvStorage = (configEnv: ConfigEnv) =>
  setSubItem(DEV_SETTINGS_KEY, CONFIG_ENV_KEY, configEnv);

// Bookmarks
export const getBookmarksStorage = () => getItem<Bookmark[]>(BOOKMARKS_KEY, { defaultValue: [] });

export const setBookmarksStorage = (bookmarks: Bookmark[]) => setItem(BOOKMARKS_KEY, bookmarks);

// Cache Bust
export const getCacheBustStorage = () =>
  getItem<boolean>(EPG_CACHE_BUST_KEY, { defaultValue: false });

export const setCacheBustStorage = () => setItem(EPG_CACHE_BUST_KEY, true);

export const removeBustCacheStorage = () => removeItem(EPG_CACHE_BUST_KEY);

// Closed Captions
export const getClosedCaptionsStorage = () =>
  getItem<boolean>(CLOSED_CAPTIONS_KEY, { defaultValue: false });

export const setClosedCaptionsStorage = (captionsOn: boolean) =>
  setItem<boolean>(CLOSED_CAPTIONS_KEY, captionsOn);

// Dev Experience
export const getDevExperienceStorage = () =>
  getSubItem<Experience | null>(DEV_SETTINGS_KEY, EXPERIENCE, {
    defaultValue: null,
  });

export const setDevExperienceStorage = (experience: Experience | null) => {
  setSubItem(DEV_SETTINGS_KEY, EXPERIENCE, experience);
};

export const getMLBExperienceStorage = () =>
  getItem<MLBExperienceStorage>(MLB_EXPERIENCE_KEY, {
    defaultValue: {
      deviceId: '',
    },
  });

export const setMLBExperienceStorage = (experienceInfo: MLBExperienceStorage) =>
  setItem<MLBExperienceStorage>(MLB_EXPERIENCE_KEY, experienceInfo);

// Favorite Team
export const getFavoriteTeamStorage = () => getItem<number>(FAVORITE_TEAM_KEY, { defaultValue: 0 });

export const setFavoriteTeamStorage = (favoriteTeam: number) => {
  setItem(FAVORITE_TEAM_KEY, favoriteTeam);
};

// Following Teams
export const getFollowingTeamsStorage = () =>
  getItem<number[]>(FOLLOWING_TEAMS_KEY, { defaultValue: [] });

export const setFollowingTeamsStorage = (followingTeams: number[]) => {
  setItem(FOLLOWING_TEAMS_KEY, followingTeams);
};

// Getting Started Viewed
export const getGettingStartedViewed = () =>
  getItem<boolean>(GETTING_STARTED_VIEWED_KEY, { defaultValue: false });

export const setGettingStartedViewed = (gettingStartedViewed: boolean) => {
  setItem(GETTING_STARTED_VIEWED_KEY, gettingStartedViewed);
};

// Hide Spoilers
export const getHideSpoilersStorage = () =>
  getItem<boolean>(HIDE_SPOILERS_KEY, { defaultValue: false });

export const setHideSpoilersStorage = (hideSpoilers: boolean) => {
  setItem(HIDE_SPOILERS_KEY, hideSpoilers);
};

// IsMuted For Dev
export const getIsMutedStorage = () => getItem<boolean>(IS_MUTED_KEY, { defaultValue: false });

export const setIsMutedStorage = (captionsOn: boolean) =>
  setItem<boolean>(IS_MUTED_KEY, captionsOn);

// Logging Enabled
export const getLoggingEnabledStorage = () =>
  getSubItem<boolean>(DEV_SETTINGS_KEY, LOGGING_ENABLED_KEY, {
    defaultValue: true,
  });

export const setLoggingEnabledStorage = (loggingEnabled: boolean) => {
  setSubItem(DEV_SETTINGS_KEY, LOGGING_ENABLED_KEY, loggingEnabled);
};

// Log in after create
export const getLogInAfterCreateStorage = () =>
  getSubItem<boolean>(DEV_SETTINGS_KEY, LOG_IN_AFTER_CREATE_KEY, {
    defaultValue: false,
  });

export const setLogInAfterCreateStorage = (logInAfterCreate: boolean) => {
  setSubItem(DEV_SETTINGS_KEY, LOG_IN_AFTER_CREATE_KEY, logInAfterCreate);
};

// Redirect page after onboarding flow
export const getOnboardingRedirectPage = () =>
  getItem(ONBOARDING_REDIRECT_PAGE, {
    defaultValue: ROUTES.HOME,
    type: StorageType.sessionStorage,
  });

// Okta Auth
export const getOktaAuthStorage = () => {
  const item = getItem(OKTA_AUTH_STORAGE_KEY, {
    defaultValue: { accessToken: { accessToken: '' } },
  });
  return { accessToken: item.accessToken?.accessToken };
};

export const setOnboardingRedirectPage = (page: string) =>
  setItem(ONBOARDING_REDIRECT_PAGE, page, { type: StorageType.sessionStorage });

// Title Safe Overlay
export const getShowTitleSafeOverlayStorage = () =>
  getSubItem<boolean>(DEV_SETTINGS_KEY, SHOW_TITLE_SAFE_OVERLAY_KEY, {
    defaultValue: false,
  });

export const setShowTitleSafeOverlayStorage = (showOverlay: boolean) => {
  setSubItem(DEV_SETTINGS_KEY, SHOW_TITLE_SAFE_OVERLAY_KEY, showOverlay);
};

// Text to Speech
export const getTextToSpeechEnabledStorage = () =>
  getSubItem<boolean>(DEV_SETTINGS_KEY, TEXT_TO_SPEECH_ENABLED_KEY, {
    defaultValue: false,
  });

export const setTextToSpeechEnabledStorage = (textToSpeechEnabled: boolean) => {
  setSubItem(DEV_SETTINGS_KEY, TEXT_TO_SPEECH_ENABLED_KEY, textToSpeechEnabled);
};
