import { NAVIGATION_KEY_MAP } from 'utils/controllerKeymap';

// TODO: After TTS dictionary MV is complete, these strings will be moved into the TTS dictionary
export const getArrowDirectionText = (keyCode: number) => {
  if (NAVIGATION_KEY_MAP.left.includes(keyCode)) {
    return 'to the left of';
  } else if (NAVIGATION_KEY_MAP.up.includes(keyCode)) {
    return 'above';
  } else if (NAVIGATION_KEY_MAP.right.includes(keyCode)) {
    return 'to the right of';
  } else if (NAVIGATION_KEY_MAP.down.includes(keyCode)) {
    return 'below';
  } else {
    return '';
  }
};
