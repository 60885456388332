import { actionEventIds, AdobeTracker } from 'analytics/adobe';
import FeedLockIcon from 'assets/feedSelect/feedLock.svg?react';
import {
  FeedLink,
  FeedTeamText,
} from 'components/FeedSelect/StreamSelection/StreamSelection.styles';
import { buildGameVideoToObject, shouldShowSecondaryFeedSelect } from 'components/FeedSelect/utils';
import { ROUTES } from 'constants/screens';
import { feedSelectIds } from 'constants/testIds';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { selectBookmarkTime } from 'store/bookmarks';
import { selectTeamIdToAbbrv } from 'store/config';
import { EpgAndStatsGame, EpgAudioFeed, EpgVideoFeed } from 'store/epg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { StartAt } from 'store/player';
import { setSelectedFeed } from 'store/selectedVideo';
import { monthDayYear, yearMonthDay } from 'utils/date';
import { getIsMediaOff } from 'utils/gameData';

import { TeamFeedContainer } from './FeedSelectButton.styles';

const { FEED_BUTTON, LOCK_ICON } = feedSelectIds;
const { SELECT_FEED } = actionEventIds();
const { PAYWALL } = ROUTES;

interface FeedSelectButtonProps {
  feed: EpgAudioFeed | EpgVideoFeed;
  game: EpgAndStatsGame;
}

export function FeedSelectButton({ feed, game }: FeedSelectButtonProps) {
  const { date } = useDateFromURL();
  const { callLetters, contentId, entitled, mediaFeedSubType, mediaId } = feed;
  const {
    gameData: {
      away: { teamAbbrv: awayTeamAbbrv },
      gameDate,
      home: { teamAbbrv: homeTeamAbbrv },
    },
    gamePk,
  } = game;

  const dispatch = useAppDispatch();
  const bookmarkTime = useAppSelector((state) => selectBookmarkTime(state, contentId));
  const teamIdToAbbrv = useAppSelector(selectTeamIdToAbbrv);

  // checks mediaState and will show feed buttons as long as mediaState
  // is NOT `MEDIA_OFF` - this should be removed in later feedSelect work
  const isFeedMediaOff = getIsMediaOff(feed);
  if (isFeedMediaOff) return null;

  const formattedGameDate = monthDayYear(gameDate);
  const streamMatchup = `${awayTeamAbbrv}@${homeTeamAbbrv}_${formattedGameDate}`;
  const team = teamIdToAbbrv[mediaFeedSubType];

  const onFeedSelect = () => {
    if (entitled) {
      dispatch(setSelectedFeed(feed));

      AdobeTracker.trackAction(SELECT_FEED(callLetters), {
        game: { pk: gamePk },
        stream: { matchup: streamMatchup },
      });
    }
  };

  const showSecondaryFeedSelect = shouldShowSecondaryFeedSelect({
    bookmarkTime,
    entitled,
    feed,
  });

  const buildFeedSelectToObject = () => {
    if (showSecondaryFeedSelect) return undefined;

    return entitled
      ? buildGameVideoToObject({ contentId, date, gamePk, mediaId })
      : {
          pathname: PAYWALL,
          state: { date: yearMonthDay(date), gamePk },
        };
  };

  const to = buildFeedSelectToObject();
  const focusOptions = { focusKey: contentId };

  return (
    <FeedLink
      key={callLetters}
      data-testid={`${FEED_BUTTON}-${callLetters}`}
      focusOptions={focusOptions}
      navigateOptions={{
        state: {
          date: yearMonthDay(date),
          feed,
          gamePk,
          startAt: StartAt.BEGINNING,
        },
      }}
      to={to}
      variant="button"
      onPress={onFeedSelect}>
      <TeamFeedContainer $entitled={entitled}>
        <FeedTeamText>{team}</FeedTeamText>: {callLetters}
      </TeamFeedContainer>
      {!entitled && <FeedLockIcon data-testid={LOCK_ICON} />}
    </FeedLink>
  );
}
