export enum StorageType {
  localStorage = 'localStorage',
  sessionStorage = 'sessionStorage',
}

interface StorageObject<Type> {
  [key: string]: Type;
}

interface StorageOptions {
  type: StorageType;
}

const storageApis: { [key in StorageType]: Storage } = {
  localStorage,
  sessionStorage,
};

const defaultOptions: StorageOptions = {
  type: StorageType.localStorage,
};

type GetItemOptions<Type> = Partial<StorageOptions> & { defaultValue: Type };

export function getItem<Type>(key: string, options: GetItemOptions<Type>) {
  const type = options?.type ?? defaultOptions.type;
  const { defaultValue } = options;
  try {
    const item = storageApis[type].getItem(key);
    return item ? (JSON.parse(item) as Type) : defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
}

export function getSubItem<Type>(key: string, subKey: string, options: GetItemOptions<Type>) {
  const type = options?.type ?? defaultOptions.type;
  const { defaultValue } = options;
  try {
    const item = storageApis[type].getItem(key);
    return item
      ? (JSON.parse(item) as StorageObject<Type>)?.[subKey] ?? defaultValue
      : defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
}

export function removeItem(key: string, options = defaultOptions): void {
  try {
    storageApis[options.type].removeItem(key);
  } catch (e) {
    console.error(e);
  }
}

export function setItem<Type>(key: string, value: Type, options = defaultOptions): void {
  try {
    storageApis[options.type].setItem(key, JSON.stringify(value));
  } catch (e) {
    console.error(e);
  }
}

export function setSubItem<Type>(
  key: string,
  subKey: string,
  value: Type,
  options = defaultOptions,
): void {
  try {
    const item: StorageObject<Type> = getItem<Type>(key, { defaultValue: undefined as any }) ?? {};
    item[subKey] = value;

    storageApis[options.type].setItem(key, JSON.stringify(item));
  } catch (e) {
    console.error(e);
  }
}
