import { buildGameVideoToObject } from 'components/FeedSelect/utils';
import { ROUTES } from 'constants/screens';
import { useDateFromURL } from 'hooks/useDateFromURL';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { selectDeepLink, selectIsAppInitialized, setDeepLink } from 'store/app';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectIsAuthenticated } from 'store/mlbAuth';
import { buildForgeVideoToObject } from 'utils/forgeVideo';

export function useLaunchArguments() {
  const { date } = useDateFromURL();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAppInitialized = useAppSelector(selectIsAppInitialized);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const storeDeepLink = useAppSelector(selectDeepLink);

  const [sonyDeepLink, setSonyDeepLink] = useState('');

  useEffect(() => {
    if (!isAppInitialized) return;
    async function getDeepLink() {
      try {
        const launchArgument = await window.msdk.device.getLaunchArgument();
        setSonyDeepLink(launchArgument);
      } catch (e) {
        console.error(`Failed to get the device launch argument: ${e}`);
      }
    }

    if (window.msdk) {
      getDeepLink();
    }
  }, [isAppInitialized, sonyDeepLink]);

  useEffect(() => {
    if (sonyDeepLink !== storeDeepLink) {
      dispatch(setDeepLink(sonyDeepLink));
      const deepLinkSplit = sonyDeepLink?.split('&');
      const gamePk = deepLinkSplit?.[0]?.split('=')[1] ?? '';
      const contentId = deepLinkSplit?.[1]?.split('=')[1];
      const mediaId = deepLinkSplit?.[2]?.split('=')[1];
      const slug = deepLinkSplit?.[3]?.split('=')[1];

      if (gamePk && contentId && mediaId) {
        if (!isAuthenticated) {
          navigate(ROUTES.WELCOME, {
            state: {
              date,
              from: ROUTES.GAMES,
              gamePk,
            },
          });
          return;
        }

        const toGame = buildGameVideoToObject({
          contentId,
          date,
          gamePk,
          mediaId,
        });
        navigate(toGame, {
          state: {
            date,
            from: ROUTES.GAMES,
            gamePk,
          },
        });
      }

      if (slug && !gamePk) {
        const to = buildForgeVideoToObject(slug);
        navigate(to, {
          state: {
            date,
            from: ROUTES.HOME,
          },
        });
      }
    }
  }, [date, dispatch, isAuthenticated, navigate, sonyDeepLink, storeDeepLink]);
}
