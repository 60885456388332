import { isNeitherNilOrEmpty } from './utils';
import { utteranceFromElementDescription } from './utteranceFromElementDescription';
import { utteranceFromElementLabel } from './utteranceFromElementLabel';
import { utteranceFromElementOrText } from './utteranceFromElementOrText';

/**
 * Returns an array strings for use with text to speech. Given a dom element,
 * text is collected from that element and ancestor elements via their aria attributes.
 *
 * Ancestor elements are matched based on whether or not they have a
 * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles role}
 * attribute present.
 *
 * **Supported Aria Attributes**:
 *
 * * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby aria-labelledby}
 * * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label aria-label}
 * * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-describedby aria-describedby}
 * * {@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description aria-description}
 *
 *
 * **Example Usage**:
 * ```
 * utterancesFromElement(document.getElementById('target-button'));
 * ```
 *
 *
 * **Nested Content**:
 * ```
 * <div id="home-screen" role="main" aria-label="On the Home Screen">
 *   <div id="main-menu" role="menu" aria-label="In the Main Menu" aria-description="1 of 1 links">
 *     <button id="target-button">Home</button>
 *   </div>
 * </div>
 * ```
 * Output: ```["On the Home Screen", "In the Main Menu", "Home", "1 of 1 links"]```
 */

export const utteranceListFromElement = (element: Element) => {
  const utterances = [
    utteranceFromElementOrText(element),
    utteranceFromElementDescription(element),
  ];

  let currentElement = element.parentElement;

  while (currentElement instanceof HTMLElement) {
    if (currentElement.getAttribute('role')) {
      utterances.unshift(utteranceFromElementLabel(currentElement));
      utterances.push(utteranceFromElementDescription(currentElement));
    }

    currentElement = currentElement.parentElement && currentElement.parentElement.closest('[role]');
  }

  return utterances.filter(isNeitherNilOrEmpty);
};
