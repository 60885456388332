import { setUserId } from '@convivainc/conviva-js-appanalytics';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Experience } from '@mlbtv-clients/services';
import SDK from 'bam-browser-sdk/dist/browser_browser_es5/bam-browser-sdk';
import * as DSSSdk from 'experience/DSS';
import { MLBExperience } from 'experience/MLB';
import { RequestStatus } from 'services/api';
import { AppDispatch, GetState } from 'store';
import { initAdobe, initAdobeTargetFlow } from 'store/adobe';
import { getUserCountryCodeFlow, selectConfigEnv } from 'store/app';
import { setBookmarks } from 'store/bookmarks';
import { fetchBrandingFlow } from 'store/branding';
import { selectConfig, selectConvivaConfig, selectDatadogConfig } from 'store/config';
import {
  authorizeDSSSessionFlow,
  getExperienceFlow,
  registerMLBSessionFlow,
  setExperienceStatus,
} from 'store/experience';
import { selectOktaId } from 'store/mlbAuth';
import {
  fetchProfileFlow,
  selectEmail,
  selectName,
  setFavoriteTeam,
  setFollowingTeams,
  setHideSpoilers,
  updateProfileFlow,
} from 'store/profile';
import { initializeConvivaEco } from 'utils/convivaEco';
import { initializeDatadog } from 'utils/datadog';
import {
  getBookmarksStorage,
  getFavoriteTeamStorage,
  getFollowingTeamsStorage,
  getHideSpoilersStorage,
  getLogInAfterCreateStorage,
} from 'utils/storage';

export const authenticatedFlow = () => async (dispatch: AppDispatch) => {
  const isLoginAfterCreate = getLogInAfterCreateStorage();
  const favoriteTeamStorage = getFavoriteTeamStorage();
  const followingTeamsStorage = getFollowingTeamsStorage();

  try {
    if (isLoginAfterCreate) {
      await dispatch(
        updateProfileFlow({
          followedTeams: followingTeamsStorage,
          primaryFavorite: favoriteTeamStorage,
        }),
      );
    } else {
      await dispatch(fetchProfileFlow());
    }

    const experience = await dispatch(initExperienceFlow());

    if (experience === Experience.LEGACY) {
      await dispatch(authorizeDSSSessionFlow());
    } else {
      await dispatch(registerMLBSessionFlow());
    }
  } catch (e) {
    console.error(`Authentication failed, ${e.message}`);
  }
};

export const nonAuthenticatedFlow = () => async (dispatch: AppDispatch) => {
  const experience = await dispatch(initExperienceFlow());

  if (experience === Experience.MLB) {
    await dispatch(registerMLBSessionFlow());
  } else {
    dispatch(setExperienceStatus(RequestStatus.SUCCESS));
  }

  try {
    dispatch(setFavoriteTeam(getFavoriteTeamStorage()));
    dispatch(setFollowingTeams(getFollowingTeamsStorage()));
    dispatch(setHideSpoilers(getHideSpoilersStorage()));
  } catch (e) {
    console.warn('Could not fetch data from localStorage', e);
  }
};

export const initDSSExperienceFlow = () => async (dispatch: AppDispatch, getState: GetState) => {
  const config = selectConfig(getState());
  const session = await DSSSdk.init(config);

  session.on(SDK.Events.ReauthorizationFailure, async (e: any) => {
    console.error('REAUTHORIZATION FAILED:', e);
    await dispatch(authorizeDSSSessionFlow());
  });
};

export const initExperienceFlow = () => async (dispatch: AppDispatch, getState: GetState) => {
  const configEnv = selectConfigEnv(getState());
  await MLBExperience.init(configEnv);

  const experience = await dispatch(getExperienceFlow());

  if (experience === Experience.LEGACY) {
    await dispatch(initDSSExperienceFlow());
  }

  return experience;
};

export const setupAppFlow = () => async (dispatch: AppDispatch, getState: GetState) => {
  const state = getState();
  const convivaConfig = selectConvivaConfig(state);
  const datadogConfig = selectDatadogConfig(state);

  dispatch(getUserCountryCodeFlow());
  dispatch(setBookmarks(getBookmarksStorage()));

  await Promise.all([
    dispatch(initAdobe()),
    dispatch(initAdobeTargetFlow()),
    dispatch(fetchBrandingFlow()),
  ]);

  initializeConvivaEco(convivaConfig);
  initializeDatadog(datadogConfig);
};

export const setDatadogUser = () => (_: AppDispatch, getState: GetState) => {
  const state = getState();
  const email = selectEmail(state);
  const name = selectName(state);
  const oktaId = selectOktaId(state);

  datadogRum.setUser({ email, id: oktaId, name });
  datadogLogs.setUser({ email, id: oktaId, name });
  setUserId(oktaId);
};
