import {
  FocusableComponentLayout,
  FocusDetails,
  useFocusable,
  UseFocusableConfig,
} from '@noriginmedia/norigin-spatial-navigation';
import cx from 'classnames';
import { useSmoothScrollerContext } from 'context/SmoothScroller';
import merge from 'lodash/merge';
import { ReactNode, useCallback } from 'react';
import {
  LinkProps as RouterLinkProps,
  NavigateOptions,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { getNodeCenterYPosition } from 'utils/focus';

import { BaseLink, ButtonLink } from './Link.styles';

const linkVariants = {
  base: BaseLink,
  button: ButtonLink,
} as const;

type LinkVariant = keyof typeof linkVariants;

type FocusOptions = Partial<UseFocusableConfig> & {
  overrideDefaultFocusBehavior?: boolean;
};

export interface LinkProps {
  active?: boolean;
  children: ReactNode;
  className?: string;
  'data-testid'?: string;
  focusOptions?: FocusOptions;
  navigateOptions?: NavigateOptions;
  onPress?: () => void;
  to?: RouterLinkProps['to'];
  variant?: LinkVariant;
}

export function Link({
  active = false,
  children,
  className,
  focusOptions = { overrideDefaultFocusBehavior: false },
  navigateOptions = {},
  onPress,
  to,
  variant = 'base',
  ...props
}: LinkProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const setY = useSmoothScrollerContext();

  const onFocus = (layout: FocusableComponentLayout, focusProps: object, details: FocusDetails) => {
    if (focusOptions.onFocus) {
      focusOptions.onFocus(layout, focusProps, details);
    }

    if (!focusOptions.overrideDefaultFocusBehavior) {
      const position = getNodeCenterYPosition(layout);
      if (position !== null && setY) setY(position);
    }
  };

  const onEnterRelease = useCallback(() => {
    if (onPress) onPress();

    if (to) {
      const options = merge(navigateOptions, {
        state: { from: pathname },
      });
      navigate(to, options);
    }
  }, [navigate, navigateOptions, onPress, pathname, to]);

  const { focusSelf, focused, ref } = useFocusable({
    ...focusOptions,
    onEnterRelease,
    onFocus,
  });

  // Used to help QA engineers focus elements directly in tests
  const handleFocusSelf = () => focusSelf();

  const Variant = linkVariants[variant];

  return (
    <Variant
      ref={ref}
      className={cx(className, { active, focused })}
      role="link"
      tabIndex={-1}
      onFocus={handleFocusSelf}
      {...props}>
      {children}
    </Variant>
  );
}
