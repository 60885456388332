import { selectFeatureFlags } from 'store/config';
import { useAppSelector } from 'store/hooks';
import { getTextToSpeechEnabledStorage } from 'utils/storage';
import { speak } from 'utils/textToSpeech';

const noop = () => {};

export const useTextToSpeech = () => {
  const { hasTextToSpeech } = useAppSelector(selectFeatureFlags);
  const textToSpeechEnabled = getTextToSpeechEnabledStorage();

  const ttsEnabled = hasTextToSpeech && textToSpeechEnabled;

  return ttsEnabled ? speak : noop;
};
