import {
  DAY_OF_WEEK_MONTH_DAY,
  EASTERN_TZ,
  FORMAT_TZ_PATTERN,
  HOUR_MINUTE_AMPM,
  MONTH_DAY,
  MONTH_DAY_YEAR,
  YEAR_MONTH_DAY,
  YEAR_MONTH_DAY_TIME,
} from 'constants/date';
import {
  addDays as addDaysDateFns,
  addHours,
  addMilliseconds as addMillisecondsDateFns,
  addMinutes as addMinutesDateFns,
  addMonths as addMonthsDateFns,
  addSeconds as addSecondsDateFns,
  differenceInMinutes,
  differenceInSeconds as differenceInSecondsDateFns,
  endOfMonth as endOfMonthDateFns,
  format as formatDateFns,
  getDay as getDayDateFns,
  getDaysInMonth as getDaysInMonthDateFns,
  getMonth as getMonthDateFns,
  getYear as getYearDateFns,
  isAfter as isAfterDateFns,
  isFirstDayOfMonth as isFirstDayOfMonthDateFns,
  isFuture as isFutureDateFns,
  isLastDayOfMonth as isLastDayOfMonthDateFns,
  isPast as isPastDateFns,
  isSameMonth as isSameMonthDateFns,
  isToday as isTodayDateFns,
  lightFormat,
  parseISO as parseISODateFns,
  startOfDay as startOfDayDateFns,
  startOfMonth as startOfMonthDateFns,
  startOfToday as startOfTodayDateFns,
  startOfYesterday as startOfYesterdayFns,
  subDays as subDaysDateFns,
  subMonths as subMonthsDateFns,
} from 'date-fns';
import { format as formatTZ, toDate as toDateTZ, utcToZonedTime } from 'date-fns-tz';

export const addDays = (date: Date | number | string, days: number): Date =>
  addDaysDateFns(new Date(date), days);

export const addMinutes = (date: Date | number | string, minutes: number): Date =>
  addMinutesDateFns(new Date(date), minutes);

export const addMilliseconds = (date: Date | number | string, minutes: number): Date =>
  addMillisecondsDateFns(new Date(date), minutes);

export const addMonths = (date: Date | number | string, months: number): Date =>
  addMonthsDateFns(new Date(date), months);

export const addSeconds = (date: Date | number | string, seconds: number): Date =>
  addSecondsDateFns(new Date(date), seconds);

export const addTimezoneOffsetToDate = (date: Date | number | string): Date => {
  // if dateString = 2020-05-09
  // selected date will be 2020-05-08 18:00:00 GMT-600
  // then selectedDateAtUTC will be 2020-05-09 00:00:00 GMT-600
  const dateObj = new Date(date);
  const timezoneOffset = dateObj.getTimezoneOffset() / 60;

  return addHours(dateObj, timezoneOffset);
};

// Wednesday, January 15
export const dayOfWeekMonthDay = (date: Date): string => format(date, DAY_OF_WEEK_MONTH_DAY);

export const differenceInMinutesFromNow = (date: Date | number | string): number =>
  differenceInMinutes(new Date(Date.now()), new Date(date));

// Return the difference in milliseconds (date2 - date1)
export const differenceInMs = (date1: Date | string, date2: Date | string): number =>
  differenceInSecondsDateFns(new Date(date1), new Date(date2)) * 1000;

export const endOfMonth = (date: Date | number | string) => endOfMonthDateFns(new Date(date));

export const format = formatDateFns;

// January 15, 2019
export const fullMonthDayYear = (date: Date | number | string): string =>
  format(new Date(date), 'MMMM d, yyyy');

export const getDay = getDayDateFns;

export const getDaysInMonth = getDaysInMonthDateFns;

export const getMonth = (date: Date | number | string): number => getMonthDateFns(new Date(date));

export const getTimeInMs = (date: Date | number | string = new Date()) => new Date(date).getTime();

export const getYear = (date: Date | number | string): number => getYearDateFns(new Date(date));

// 5:07 PM
export const hourMinuteAMPM = (date: string): string =>
  format(parseISODateFns(date), HOUR_MINUTE_AMPM);

export const isDateFuture = (date: Date | number | string): boolean => {
  const dateWithoutTimeStamp = startOfDayDateFns(new Date(date));

  return isFutureDateFns(new Date(dateWithoutTimeStamp));
};

export const isFirstDayOfMonth = (date: Date | number | string): boolean =>
  isFirstDayOfMonthDateFns(new Date(date));

export const isLastDayOfMonth = (date: Date | number | string): boolean =>
  isLastDayOfMonthDateFns(new Date(date));

export const isPast = (date: Date | number | string): boolean => isPastDateFns(new Date(date));

export const isSameMonth = (
  date1: Date | number | string,
  date2: Date | number | string,
): boolean => isSameMonthDateFns(new Date(date1), new Date(date2));

export const isToday = (date: Date | number | string) => isTodayDateFns(new Date(date));

// Jan 15th
export const monthDay = (date: Date | number | string): string => format(new Date(date), MONTH_DAY);

// 01/15/2020
export const monthDayYear = (date: Date | number | string): string =>
  lightFormat(new Date(date), MONTH_DAY_YEAR);

export const parseISO = (date: string): Date => parseISODateFns(date);

export const secondsUntilDate = (date: Date | number | string): number =>
  differenceInSecondsDateFns(new Date(date), new Date(Date.now()));

export const startOfDay = (date: Date | number | string = new Date()) =>
  startOfDayDateFns(new Date(date));

export const startOfMonth = (date: Date | number | string) => startOfMonthDateFns(new Date(date));

export const startOfToday = (): Date => startOfTodayDateFns();

export const startOfYesterday = (): Date => startOfYesterdayFns();

export const stringToDate = (dateString: string): Date => new Date(dateString);

export const subDays = (date: Date | number | string, days: number): Date =>
  subDaysDateFns(new Date(date), days);

export const subMonths = (date: Date | number | string, months: number): Date =>
  subMonthsDateFns(new Date(date), months);

export type TenAmEasternInfo = {
  isAfterTenAm: boolean;
  msUntilTenAm: number;
};

export const tenAmEasternInfo = (): TenAmEasternInfo => {
  const easternTime = new Date(Date.now());
  easternTime.setHours(10, 0, 0, 0);

  const tenAmEastern = formatTZ(easternTime, FORMAT_TZ_PATTERN, {
    timeZone: EASTERN_TZ,
  });
  const tenAmDate = toDateTZ(tenAmEastern, { timeZone: EASTERN_TZ });

  const currentTime = new Date(Date.now());

  return {
    isAfterTenAm: isAfterDateFns(currentTime, tenAmDate),
    msUntilTenAm: secondsUntilDate(tenAmDate) * 1000 + 2000,
  };
};

export const toISOString = (date: Date | number | string = new Date(Date.now())): string =>
  new Date(date).toISOString();

// 2020-01-15
export const yearMonthDay = (date: Date | number | string): string =>
  lightFormat(new Date(date), YEAR_MONTH_DAY);

// 20201223_150734 (yearMonthDay_HourMinuteSecond)
// From ISO string to linescore time string
export const yearMonthDayHourMinuteSecond = (date: Date): string =>
  format(utcToZonedTime(date, 'UTC'), YEAR_MONTH_DAY_TIME);
