import { useCallback, useEffect, useState } from 'react';
import { getArrowDirectionText } from './utils';
import { ARROW_KEYS } from 'constants/spatialNavigation';
import { useTextToSpeech } from 'hooks/useTextToSpeech/useTextToSpeech';
import { utteranceListFromElement } from '../../App/textToSpeech/utteranceListFromElement';
import { without } from 'lodash';

export const useTTSNavigation = () => {
  const [prevActiveElement, setPrevActiveElement] = useState<Element | null>(null);
  const [prevUtteranceList, setPrevUtteranceList] = useState<string[]>([]);

  const speak = useTextToSpeech();

  const handleKeyup = useCallback(
    (e: KeyboardEvent) => {
      const isArrowPress = ARROW_KEYS.has(e.keyCode);
      const focusChanged = prevActiveElement !== document.activeElement;

      // focus did not change
      if (isArrowPress && !focusChanged) {
        const arrowDirectionText = getArrowDirectionText(e.keyCode);

        // TODO: This text will be moved to configs
        speak(
          `There is no selectable content available ${arrowDirectionText} the current position`,
        );
      }

      // focus changed
      if (focusChanged && e.target instanceof HTMLElement) {
        const newUtteranceList = utteranceListFromElement(e.target);
        const utteranceToSpeak = without(newUtteranceList, ...prevUtteranceList).join(' -- ');

        const liveRegion = document.getElementById('aria-live-region');
        if (liveRegion) {
          liveRegion.textContent = utteranceToSpeak;
        } else {
          speak(utteranceToSpeak);
        }

        setPrevUtteranceList(newUtteranceList);
      }

      setPrevActiveElement(document.activeElement);
    },
    [prevActiveElement, prevUtteranceList, speak],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyup);

    return () => {
      window.removeEventListener('keyup', handleKeyup);
    };
  }, [handleKeyup]);
};
