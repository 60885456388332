import axios from 'axios';
import { Config, Map, Maps } from 'store/config';
import { isNonProdBuild } from 'utils/env';

const qaConfigUrlBase =
  'https://storage.googleapis.com/mlb-webeng-npd-mlb-static-qa/mlbtv-app-configs';
const configUrlBase = 'https://www.mlbstatic.com/mlbtv-app-configs';

export const fetchMaps = async (env: string): Promise<Maps> => {
  const isQaUrlBase = isNonProdBuild() || ['qa', 'dev'].includes(env);
  const baseUrl = isQaUrlBase ? qaConfigUrlBase : configUrlBase;
  const mapsUrl = `${baseUrl}/maps/${__PLATFORM__}.json`;
  const response = await axios.get<any>(mapsUrl);
  return response.data;
};

export const fetchConfig = async (env: string): Promise<Config> => {
  const isQaUrlBase = isNonProdBuild() || ['qa', 'dev'].includes(env);
  const baseUrl = isQaUrlBase ? qaConfigUrlBase : configUrlBase;
  const maps = await fetchMaps(env);
  const version = getConfigVersion(maps, __VERSION__, env);

  const configUrl = `${baseUrl}/${version}/${__PLATFORM__}/${env}.json`;
  const response = await axios.get<Config>(configUrl);

  return response.data;
};

export const getConfigVersion = (maps: Maps, version: string, env: string) => {
  const map = maps?.[version] as Map | undefined;
  return map?.[env] ?? map?.default ?? maps?.default;
};
