import { usePlayerContext } from 'context/PlayerContext';
import { useCallback } from 'react';
import {
  selectAudioIndexBookmark,
  selectBookmarks,
  selectBookmarkTime,
  setBookmarks,
} from 'store/bookmarks';
import { selectBookmarksConfig } from 'store/config';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { findBookmarkIndex } from 'utils/player';
import { setBookmarksStorage } from 'utils/storage';

export function useBookmarks(contentId: string) {
  const dispatch = useAppDispatch();

  const { hls } = usePlayerContext();

  const currentBookmarks = useAppSelector(selectBookmarks);
  const { MAX_BOOKMARKS } = useAppSelector(selectBookmarksConfig);
  const bookmarkTime = useAppSelector((state) => selectBookmarkTime(state, contentId));
  const audioIndexBookmark = useAppSelector((state) => selectAudioIndexBookmark(state, contentId));
  const newBookmarkIndex = findBookmarkIndex(currentBookmarks, contentId);

  const updateBookmark = useCallback(
    (updatedCurrentTime: number, isSwitchingVideoFeed = false) => {
      const audioOverlayIndex = isSwitchingVideoFeed ? 0 : hls?.audioTrack ?? audioIndexBookmark;

      const newBookmark = {
        [contentId]: {
          audioOverlayIndex,
          time: updatedCurrentTime,
        },
      };

      let updatedBookmarks = [...currentBookmarks];

      if (newBookmarkIndex > -1) {
        updatedBookmarks = [
          ...updatedBookmarks.slice(0, newBookmarkIndex),
          ...updatedBookmarks.slice(newBookmarkIndex + 1),
          newBookmark,
        ];
      } else {
        const prevBookmarks =
          currentBookmarks.length < MAX_BOOKMARKS ? currentBookmarks : currentBookmarks.slice(1);
        updatedBookmarks = [...prevBookmarks, newBookmark];
      }

      dispatch(setBookmarks(updatedBookmarks));
      setBookmarksStorage(updatedBookmarks);
    },
    [
      audioIndexBookmark,
      contentId,
      dispatch,
      currentBookmarks,
      hls,
      MAX_BOOKMARKS,
      newBookmarkIndex,
    ],
  );

  return { bookmarkTime, updateBookmark };
}
