import { Experience } from '@mlbtv-clients/services';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { HeaderButtonType } from 'constants/screens';
import invert from 'lodash/invert';
import { RequestStatus } from 'services/api';
import { fetchConfig } from 'services/config';
import { RootState } from 'store';
import { EpgAndStatsGame } from 'store/epg';
import { selectExperience } from 'store/experience';
import { getYear } from 'utils/date';
import { isNonProdBuild } from 'utils/env';

import fallback from './fallback.json';
import { Config, ConfigHeaderButton } from './types';

const { IDLE, LOADING, SUCCESS } = RequestStatus;

export interface ConfigState {
  data: Config;
  status: RequestStatus;
}

export const initialState: ConfigState = {
  data: fallback as Config,
  status: IDLE,
};

// Flows
export const fetchConfigFlow = createAsyncThunk<Config, undefined, { state: RootState }>(
  'config/fetchConfig',
  async (_, { getState }) => {
    const {
      app: { configEnv },
    } = getState();

    const env = isNonProdBuild() ? configEnv : __APP_ENV__;
    try {
      const config = await fetchConfig(env);
      return config;
    } catch (e) {
      return fallback;
    }
  },
);

// Slice
export const configSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchConfigFlow.pending, (state) => {
      state.status = LOADING;
    });
    builder.addCase(fetchConfigFlow.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = SUCCESS;
    });
  },
  initialState,
  name: 'config',
  reducers: {},
});

// Selectors
export const selectConfig = (state: RootState) => state.config.data ?? fallback;

export const selectConfigStatus = (state: RootState) => state.config.status;

export const selectBookmarksConfig = (state: RootState) =>
  state.config.data.bookmarks ?? fallback.bookmarks;

export const selectConvivaConfig = (state: RootState) =>
  state.config.data.conviva ?? fallback.conviva;

export const selectDatadogConfig = (state: RootState) =>
  state.config.data.datadog ?? fallback.datadog;

export const selectDatesConfig = (state: RootState) => state.config.data.dates ?? fallback.dates;

export const selectEntitlementsConfig = (state: RootState) =>
  state.config.data.entitlements ?? fallback.entitlements;

export const selectFeatureFlags = (state: RootState) =>
  state.config.data.featureFlags ?? fallback.featureFlags;

export const selectGoogleIMA = (state: RootState) =>
  state.config.data.googleIMA ?? fallback.googleIMA;

export const selectHeaderButtons = (state: RootState) =>
  state.config.data.headerButtons ?? fallback.headerButtons;

export const selectHLSConfig = (state: RootState) =>
  state.config.data.hlsConfig ?? fallback.hlsConfig;

export const selectImages = (state: RootState) => state.config.data.images ?? fallback.images;

export const selectIntervals = (state: RootState) =>
  state.config.data.intervals ?? fallback.intervals;

export const selectOffsets = (state: RootState) => state.config.data.offsets ?? fallback.offsets;

export const selectPercentages = (state: RootState) =>
  state.config.data.percentages ?? fallback.percentages;

export const selectSDK = (state: RootState) => state.config.data.sdk ?? fallback.sdk;

export const selectServices = (state: RootState) => state.config.data.services ?? fallback.services;

export const selectStatsApi = (state: RootState) => state.config.data.statsApi ?? fallback.statsApi;

export const selectStrings = (state: RootState) => state.config.data.strings ?? fallback.strings;

export const selectTeamAbbrvToId = (state: RootState) =>
  state.config.data.statsApi.teamAbbrvToId ?? fallback.statsApi.teamAbbrvToId;

export const selectTeamIdToAbbrv = createSelector([selectStatsApi], (statsApiConfig) => {
  const { teamAbbrvToId } = statsApiConfig;
  return invert(teamAbbrvToId);
});

// createSelectors
export const selectEnabledButtons = createSelector(
  [selectHeaderButtons],
  (headerButtons) =>
    Object.values(headerButtons).filter((button) => button.enabled) as ConfigHeaderButton[],
);

export const selectButtonsByType = createSelector(
  [selectEnabledButtons, (_, type: HeaderButtonType) => type],
  (buttons, type) => Object.values(buttons).filter((button) => button.type === type),
);

export const selectShouldShowBroadcastOptions = (state: RootState) => {
  const {
    gameMenu: { hasBroadcastOptions },
  } = selectFeatureFlags(state);
  const experience = selectExperience(state);

  return experience === Experience.LEGACY ? false : hasBroadcastOptions;
};

export const selectShouldShowDetails = (state: RootState) => {
  const {
    gameMenu: { hasDetails },
  } = selectFeatureFlags(state);
  const experience = selectExperience(state);

  return experience === Experience.LEGACY ? false : hasDetails;
};

export const selectHasPodServing = createSelector(
  [selectFeatureFlags, (_, game: EpgAndStatsGame | undefined) => game],
  ({ hasPodServing }, game) => {
    const gameDate = game?.gameData?.gameDate;
    return hasPodServing && (gameDate ? getYear(new Date(gameDate)) >= 2022 : true);
  },
);

export default configSlice.reducer;
