import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { Bookmark } from './types';

export type BookmarksState = Bookmark[];

export const initialState: BookmarksState = [];

export const bookmarksSlice = createSlice({
  initialState,
  name: 'bookmarks',
  reducers: {
    setBookmarks(_state, action: PayloadAction<Bookmark[]>) {
      return action.payload;
    },
  },
});

// actions
export const { setBookmarks } = bookmarksSlice.actions;

// selectors
export const selectBookmarks = (state: RootState) => state.bookmarks;

export const selectContentIdFromProps = (_state: RootState, contentId: string | null | undefined) =>
  contentId;

export const selectAudioIndexBookmark = createSelector(
  [selectBookmarks, selectContentIdFromProps],
  (bookmarks, contentId) =>
    contentId
      ? bookmarks.find((bookmark) => Object.keys(bookmark)[0] === contentId)?.[contentId]
          .audioOverlayIndex ?? 0
      : 0,
);

export const selectBookmarkTime = createSelector(
  [selectBookmarks, selectContentIdFromProps],
  (bookmarks, contentId) =>
    contentId
      ? bookmarks.find((bookmark) => Object.keys(bookmark)[0] === contentId)?.[contentId].time ?? 0
      : 0,
);

export default bookmarksSlice.reducer;
